import { useGoogleLogin } from "@react-oauth/google";
import Google from '../src/assets/img/gmail.png';
const GoogleLoginButton=({onSuccess})=>{

      const login=  useGoogleLogin({
            onSuccess:onSuccess,
           // flow: 'auth-code',
           
          });
      
    return(
        <img src={Google} onClick={login} className="sso" style={{cursor: "pointer",}} alt="google"/> 
    )
}
export default GoogleLoginButton;