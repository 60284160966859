export const BASE_URL = "/api";

export const CREATE_CLIENT = `/User/Create`;
export const LINKEDIN_CLIENT = `/User/GetLinkedInCredentials`;
export const APPLE_CLIENT = `/User/GetAppleLogin`;
export const GOOGLE_CLIENT_ID =
  "967420625299-163l953v4npomfhdiv3jbcvgat9h5a8f.apps.googleusercontent.com";
export const FACEBOOK_CLIENT_ID = "687763063204464";

export const FACEBOOK_SECRET = "221cbabbb8b58b820e3bc883377f2b66";
export const APPLE_CLIENT_ID = "com.insipre.babelWifi";
export const APPLE_SECRET = "";
export const LINKEDIN_CLIENT_ID = "78amheqyykwvft";
export const LINKEDIN_CLIENT_SECRET = "eTouDVFWNYXCY5yi";
