import Facebook from "../src/assets/img/fb.png";
import linkedin from "../src/assets/img/linkedin.png";
import Babel from "../src/assets/img/BabelLogo-01.png";
import Video from "../src/assets/img/BabelDubai-Wifi-LaptopSize.mp4";
import "./App.css";
import {
  GoogleOAuthProvider,
  GoogleLogin,
  useGoogleLogin,
} from "@react-oauth/google";
import FacebookLogin, {
  isFacebookApp,
} from "@greatsumini/react-facebook-login";
import { useEffect, useState } from "react";
import { createClient, linkedInClient } from "./api/apis";
import { FACEBOOK_CLIENT_ID, GOOGLE_CLIENT_ID } from "./api/endpoints";
import GoogleLoginButton from "./GoogleLogin";
import axios from "axios";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import AppleLoginComponent from "./AppleLoginComponent";
//import PopupComponent from "./PopupComponent";
import GoogleComponent from "./GoogleComponent";
import Google from "../src/assets/img/gmail.png";

function App() {
  const queryParameters = new URLSearchParams(window.location.search);
  const [linkedinSuccess, setLinkedinSuccess] = useState(false);
  const [base, setBase] = useState();
  const [continueUrl, setContinueUrl] = useState();
  //const [openPopup, setOpenPopup] = useState(false);

  // const closePopup = () => {
  //   setOpenPopup(false);
  // };

  // if (
  //   queryParameters.get("error") !== null &&
  //   queryParameters.get("error") !== ""
  // ) {
  //   setOpenPopup(true);
  // }

  if (
    queryParameters.get("code") !== null &&
    queryParameters.get("code") !== "" &&
    localStorage.getItem("Platform") !== "Apple" &&
    localStorage.getItem("Platform") === "LinkedIn"
  ) {
    localStorage.setItem("linkedinCode", queryParameters.get("code"));
  }
  if (
    queryParameters.get("code") !== null &&
    queryParameters.get("code") !== "" &&
    localStorage.getItem("Platform") === "Apple" &&
    localStorage.getItem("Platform") !== "LinkedIn"
  ) {
    localStorage.setItem("appleCode", queryParameters.get("code"));
  }
  const linked = async () => {
    const request = await linkedInClient({
      platform: "LinkedIn",
      code: localStorage.getItem("linkedinCode"),
      clientIp: localStorage.getItem("clientIp"),
      nodeId: localStorage.getItem("nodeId"),
      clientMac: localStorage.getItem("clientMac"),
      nodeMac: localStorage.getItem("nodeMac"),
      gatewayid: localStorage.getItem("gatewayId"),
      continueUrl: localStorage.getItem("continueUrl"),
      baseGrantUrl: localStorage.getItem("baseGrantUrl"),
    });
    if (request === true) {
      console.log("request chi" + request.result);
      setLinkedinSuccess(true);
      setBase(localStorage.getItem("baseGrantUrl"));
      setContinueUrl(localStorage.getItem("continueUrl"));
      //localStorage.clear();

      console.log("request sent " + request);
      console.log("continue url:  " + localStorage.getItem("continueUrl"));
      //localStorage.clear();
      // window.location.href =
      //   base + "?continue_url=" + continueUrl + "&duration=20";
    } else {
      // setOpenPopup(true);
      localStorage.setItem("error", "error 1");
    }
  };
  if (
    localStorage.getItem("Platform") === "LinkedIn" &&
    localStorage.getItem("linkedinCode") !== null
  ) {
    console.log("if");

    linked();
    //localStorage.clear();
  }

  useEffect(() => {
    if (linkedinSuccess === true) {
      window.location.href =
        base + "?continue_url=" + continueUrl + "&duration=7200";
    } else {
      //  if (linkedinSuccess === false) setOpenPopup(true);
      //localStorage.setItem("error", "error");
    }
  }, [linkedinSuccess]);

  if (
    queryParameters.get("client_ip") !== null &&
    queryParameters.get("client_ip") !== ""
  ) {
    localStorage.setItem("clientIp", queryParameters.get("client_ip"));
  }
  if (
    queryParameters.get("node_id") !== null &&
    queryParameters.get("node_id") !== ""
  ) {
    localStorage.setItem("nodeId", queryParameters.get("node_id"));
  }
  if (
    queryParameters.get("base_grant_url") !== null &&
    queryParameters.get("base_grant_url") !== ""
  ) {
    localStorage.setItem("baseGrantUrl", queryParameters.get("base_grant_url"));
  }
  if (
    queryParameters.get("gateway_id") !== null &&
    queryParameters.get("gateway_id") !== ""
  ) {
    localStorage.setItem("gatewayId", queryParameters.get("gateway_id"));
  }
  if (
    queryParameters.get("client_mac") !== null &&
    queryParameters.get("client_mac") !== ""
  ) {
    localStorage.setItem("clientMac", queryParameters.get("client_mac"));
  }
  if (
    queryParameters.get("user_continue_url") !== null &&
    queryParameters.get("user_continue_url") !== ""
  ) {
    localStorage.setItem(
      "continueUrl",
      "https://www.instagram.com/babel_dubai/"
      //queryParameters.get("user_continue_url")
    );
  }
  if (
    queryParameters.get("node_mac") !== null &&
    queryParameters.get("node_mac") !== ""
  ) {
    localStorage.setItem("nodeMac", queryParameters.get("node_mac"));
  }

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [social, setSocial] = useState(false);
  const [platName, setPlatformName] = useState();

  const [error, setError] = useState();

  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  function validateEmail(email) {
    let result = true;
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    result = re.test(String(email).toLowerCase());
    if (email && !result) {
      setError(true);
      result = false;
    } else {
      setEmail(email);
      result = true;
    }
    console.log("result:" + result);
    return result;
  }
  const { linkedInLogin } = useLinkedIn({
    clientId: "78amheqyykwvft",
    redirectUri: `https://babelwifi.inspiresolutions.io`,
    onSuccess: (credentialResponse) => {
      localStorage.setItem("Platform", "LinkedIn");

      // linked();

      window.location.href =
        localStorage.getItem("baseGrantUrl") +
        "?continue_url=" +
        localStorage.getItem("continueUrl") +
        "&duration=7200";
    },
    scope: "openid email profile",
    onError: (error) => {
      setCode("");
      setErrorMessage(error.errorMessage);
      console.log("1");
      localStorage.setItem("error", "Something went wrong!2");
    },
  });

  const CreateSocialUser = async (userInfo) => {
    var res = validateEmail(userInfo.email);
    console.log(res);
    if (!res) {
      console.log("error " + error.message);
      return false;
    } else {
      console.log(name + " " + email);
      const request = await createClient({
        name: userInfo.name,
        email: userInfo.email,
        platformName: userInfo.Platform,
        clientIp: localStorage.getItem("clientIp"),
        nodeId: localStorage.getItem("nodeId"),
        clientMac: localStorage.getItem("clientMac"),
        nodeMac: localStorage.getItem("nodeMac"),
        gatewayid: localStorage.getItem("gatewayId"),
        continueUrl: localStorage.getItem("continueUrl"),
        baseGrantUrl: localStorage.getItem("baseGrantUrl"),
      });

      console.log("request sent " + request);
      console.log("continue url:  " + localStorage.getItem("continueUrl"));
      window.location.href =
        localStorage.getItem("baseGrantUrl") +
        "?continue_url=" +
        localStorage.getItem("continueUrl") +
        "&duration=7200";
    }
  };

  const CreateUser = async (e) => {
    e.preventDefault();
    var res = validateEmail(email);
    console.log(res);
    if (!res) {
      //setOpenPopup(true);
      console.log("2");

      localStorage.setItem("error", "Something went wrong!3");
    } else {
      console.log(name + " " + email);

      const request = await createClient({
        name: name,
        email: email,
        platformName: "Portal",
        clientIp: localStorage.getItem("clientIp"),
        nodeId: localStorage.getItem("nodeId"),
        clientMac: localStorage.getItem("clientMac"),
        nodeMac: localStorage.getItem("nodeMac"),
        gatewayid: localStorage.getItem("gatewayId"),
        continueUrl: localStorage.getItem("continueUrl"),
        baseGrantUrl: localStorage.getItem("baseGrantUrl"),
      });
      if (request) {
        console.log("request sent " + request);
        console.log("continue url:  " + localStorage.getItem("continueUrl"));
        window.location.href =
          localStorage.getItem("baseGrantUrl") +
          "?continue_url=" +
          localStorage.getItem("continueUrl") +
          "&duration=7200";
      } else {
        console.log("3");

        localStorage.setItem("error", "Something went wrong!4");
      }
    }
  };

  return (
    <div>
      {/* {openPopup ? <PopupComponent closePopup={closePopup} /> : <></>} */}
      <form onSubmit={CreateUser} method="get">
        <video id="background-video" autoPlay loop muted poster="">
          <source src={Video} type="video/mp4" />
        </video>
        <div className="body" style={{ aligncontent: "center" }}>
          <div className="col p-logo">
            <img className="logo" src={Babel} alt="logo" />
          </div>
          <div className="col text-center p-sc">
            <span className="sc centerSSO text-center">STAY</span>
            <span className="sc centerSSO text-center">CONNECTED</span>
          </div>
          <div className="col text-center p-name">
            <span className="fname">
              <input
                type="text"
                className="txtb"
                required
                placeholder="FIRST NAME"
                onChange={(e) => setName(e.target.value)}
              />
            </span>
          </div>
          <div className="col text-center p-email">
            <span className="email">
              <input
                type="text"
                className="txtb"
                required
                placeholder="EMAIL"
                onChange={(e) => setEmail(e.target.value)}
              />
            </span>
          </div>
          <div className="col text-center p-login-txt">
            <span className="login-txt centerSSO">LOG IN WITH</span>
          </div>
          <div className="col text-center p-sso">
            <div className="centerSSO">
              {/* <img
                onClick={() => { */}
              <GoogleComponent />
              {/*
              //     localStorage.setItem("Platform", "google");
              //   }}
              //   src={Google}
              //   alt="Log in with Linked In"
              //   className="sso"
              //   style={{ cursor: "pointer" }}
              // /> */}
              {/* <FacebookLogin
                className="sso"
                appId={FACEBOOK_CLIENT_ID}
                onSuccess={(response) => {
                  setSocial(true);
                }}
                onFail={(error) => {
                  console.log("4");

                  localStorage.setItem("error", "Something went wrong!5");
                  //setOpenPopup(true);
                  console.log("Login Failed!", error);
                }}
                onProfileSuccess={(response) => {
                  console.log("Get Profile Success!", response);
                  setPlatformName("Facebook");
                  response.Platform = "Facebook";
                  CreateSocialUser(response);
                  console.log("Login Success!", response);
                }}
                fields="name,email"
                render={(renderProps) => (
                  <img
                    src={Facebook}
                    onClick={renderProps.onClick}
                    className="sso"
                    style={{ cursor: "pointer" }}
                    alt="facebook"
                  />
                )}
              /> */}
              {/* <AppleLoginComponent />{" "} */}
              <img
                onClick={() => {
                  localStorage.setItem("Platform", "LinkedIn");
                  linkedInLogin();
                }}
                src={linkedin}
                alt="Log in with Linked In"
                className="sso"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="col text-center p-login-btn">
            <button
              className="login-btn"
              type="Submit"
              style={{ cursor: "pointer" }}
            >
              LOG IN
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default App;
